<template>
  <div
    class="tab-pane fade show active"
    id="pills-home"
    role="tabpanel"
    aria-labelledby="pills-home-tab"
  >
    <!--one tab -->
    <div class="card" style="width: 100%; border: 0px">
      <div class="row p-5 mt-3 pt-0">
        <div class="col p-5 pt-0">
          <h4 class="mb-3 text-primary">Bin Type</h4>
          <select
            id="dropdown6"
            class="form-select mb-3"
            aria-label="Select example"
          >
            <option>Select Bin Type</option>
            <option value="0">0</option>
            <option value="1">1</option>
          </select>

          <h4 class="mb-3 mt-3 text-primary">Bin Height</h4>
          <input
            type="number"
            id="input1"
            class="form-control form-control-white"
            placeholder="30-500"
            required
            min="30"
            max="500"
          />
          <h4 class="mb-3 mt-3 text-primary">Bin Width</h4>
          <input
            type="number"
            id="input2"
            class="form-control form-control-white mb-3"
            placeholder="5-1000"
            required
            min="5"
            max="1000"
          />
          <div class="row">
            <div
              class="form-check form-check-custom form-check-solid form-check-sm mt-15"
            >
              <label class="form-check-label" for="flexRadioLg">
                <h3>Configuration Required Flag</h3></label
              >
              <input
                class="form-check-input mx-5 border border-primary"
                type="checkbox"
                @click="cr_check($event)"
                id="checkbox61"
              />CR
            </div>
          </div>
        </div>
        <div class="col p-5 pt-0">
          <h4 class="mb-3 text-primary">Bin Length</h4>
          <input
            type="number"
            id="input3"
            class="form-control form-control-white mb-3"
            placeholder="5-1000"
            required
            min="5"
            max="1000"
          />

          <h4 class="my-3 text-primary dropdown7">Angle</h4>
          <select
            id="dropdown7"
            class="form-select mb-3 dropdown7"
            aria-label="Select example"
          >
            <option>Select Sensor Orientation</option>
            <option value="0">0°</option>
            <option value="1">30°</option>
            <option value="2">60°</option>
            <option value="3">90°</option>
          </select>

          <h4 class="mb-3 text-primary">Relative Sensor Height</h4>
          <input
            type="number"
            id="input4"
            class="form-control form-control-white mb-5"
            placeholder="0-100"
            required
            min="0"
            max="100"
          />

          <h4 class="mb-3 text-primary">Sensor Orientation</h4>

          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="checkbox59"
              @click="checkbox59_click"
            />
            <label class="form-check-label"> Top Mount </label>
          </div>
          <div class="form-check mt-2">
            <input
              class="form-check-input"
              type="checkbox"
              id="checkbox60"
              @click="checkbox60_click"
            />
            <label class="form-check-label"> Side Mount </label>
          </div>
        </div>

        <div class="w-100 text-primary"></div>
        <div class="row"></div>
        <div class="row w-50 mx-auto">
          <div class="col">
            <a href="#" @click="button4_click($event)" class="btn btn-primary"
              >Compose DownLink 2
            </a>
          </div>
          <div class="col">
            <input
              class="form-control form-control-white mb-5 border border-primary"
              type="text"
              readonly
              v-model="model.hex"
            />
          </div>
        </div>
      </div>
    </div>

    <!--one tab end -->
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import jQuery from "jquery";
const $ = jQuery;
// window.$ = $;

export default defineComponent({
  name: "downlink2",
  components: {},
  setup() {
    const model = ref({
      hex: null,
    });

    onMounted(() => {
      $("#checkbox59").prop("checked", false);
      $("#checkbox60").prop("checked", false);
      $("#checkbox61").prop("checked", false);
    });

    function toHexString(byteArray) {
      return Array.prototype.map
        .call(byteArray, function (byte) {
          return ("0" + (byte & 0xff).toString(16)).slice(-2);
        })
        .join("")
        .toUpperCase();
    }

    function get_bit_status(value, bitNumber) {
      var ret_status = false;
      if (value & (1 << bitNumber)) {
        ret_status = true;
      }

      return ret_status;
    }
    function set_bit(value, bitNumber) {
      /* Note: Maximum 32 bit value shift is possible in JS */
      value |= 1 << bitNumber;
      return value;
    }
    function clear_bit(value, bitNumber) {
      /* Note: Maximum 32 bit value shift is possible in JS */
      value &= ~(1 << bitNumber);
      return value;
    }
    function insert_data(buffer, startBit, bitCount, value) {
      let bitStatus;
      let count;
      /* Get byte index and bit position */
      let index = parseInt(startBit / 8, 10);
      let bitPosition = parseInt(startBit % 8, 10);
      let iBuffer;

      for (count = 0; count < bitCount; count++) {
        iBuffer = 7 - index;
        bitStatus = get_bit_status(value, count);
        if (bitStatus === true) {
          /* Set bit */
          buffer[iBuffer] |= set_bit(buffer[iBuffer], bitPosition);
        } else {
          /* Clear bit */
          buffer[iBuffer] |= clear_bit(buffer[iBuffer], bitPosition);
        }

        if (bitPosition >= 7) {
          bitPosition = 0;
          index += 1;
        } else {
          bitPosition += 1;
        }
      }
    }

    const button4_click = (event) => {
      let myDownlink2 = new Uint8Array([0, 0, 0, 0, 0, 0, 0, 0]);

      insert_data(myDownlink2, 0, 3, 2);

      if ($("#dropdown6").val().length === 0) {
        $("#textBox2").value = "Please Select Bin Type";
      } else {
        insert_data(myDownlink2, 3, 5, $("#dropdown6").val());

        if (
          $("#checkbox59").is(":checked") ||
          $("#checkbox60").is(":checked")
        ) {
          if ($("#checkbox60").is(":checked")) {
            insert_data(myDownlink2, 26, 1, $("#checkbox60").is(":checked"));

            if ($("#dropdown7").val().length === 0) {
              $("#textBox2").value = "Please Select Mount Angle";
            } else {
              insert_data(myDownlink2, 23, 3, $("#dropdown7").val());

              if ($("#input1").val().length === 0) {
                $("#textBox2").value = "Please Select Bin Height";
              } else {
                insert_data(myDownlink2, 44, 10, $("#input1").val());

                if ($("#input2").val().length === 0) {
                  $("#textBox2").value = "Please Select Bin Width";
                } else {
                  insert_data(myDownlink2, 34, 10, $("#input2").val());

                  if ($("#input3").val().length === 0) {
                    $("#textBox2").value = "Please Select Bin Length";
                  } else {
                    insert_data(myDownlink2, 54, 10, $("#input3").val());

                    if ($("#input4").val().length === 0) {
                      $("#textBox2").value = "Select Relative Sensor Height";
                    } else {
                      insert_data(myDownlink2, 27, 7, $("#input4").val());

                      if ($("#checkbox61").is(":checked")) {
                        insert_data(
                          myDownlink2,
                          8,
                          1,
                          $("#checkbox61").is(":checked")
                        );

                        let hexString2 = toHexString(myDownlink2);
                        model.value.hex = hexString2;
                      } else {
                        let hexString2 = toHexString(myDownlink2);
                        model.value.hex = hexString2;
                        // $("#textBox2").value=hexString2;
                        // console.log(hexString2);
                      }
                    }
                  }
                }
              }
            }
          } else {
            if ($("#input1").val().length === 0) {
              $("#textBox2").value = "Please Select Bin Height";
            } else {
              insert_data(myDownlink2, 44, 10, $("#input1").val());

              if ($("#input2").val().length === 0) {
                $("#textBox2").value = "Please Select Bin Width";
              } else {
                insert_data(myDownlink2, 34, 10, $("#input2").val());

                if ($("#input3").val().length === 0) {
                  $("#textBox2").value = "Please Select Bin Length";
                } else {
                  insert_data(myDownlink2, 54, 10, $("#input3").val());

                  if ($("#input4").val().length === 0) {
                    $("#textBox2").value = "Select Relative Sensor Height";
                  } else {
                    insert_data(myDownlink2, 27, 7, $("#input4").val());

                    if ($("#checkbox61").is(":checked")) {
                      insert_data(
                        myDownlink2,
                        8,
                        1,
                        $("#checkbox61").is(":checked")
                      );

                      let hexString2 = toHexString(myDownlink2);
                      model.value.hex = hexString2;
                    } else {
                      let hexString2 = toHexString(myDownlink2);
                      model.value.hex = hexString2;
                    }
                  }
                }
              }
            }
          }
        } else {
          $("#textBox2").value = "Select any mount type";
        }
      }
    };

    // Code to enable mount angle window

    const checkbox60_click = (event) => {
      $("#checkbox59").prop("checked", false);

      if ($("#checkbox60").is(":checked")) {
        $("#dropdown7").hide();
        $(".dropdown7").hide();
      } else {
        $("#dropdown7").show();
        $(".dropdown7").show();
      }
    };

    const cr_check = (e) => {
      if ($("#checkbox61").is(":checked")) {
        $("#checkbox61").prop("checked", false);
      } else {
        $("#checkbox61").prop("checked", true);
      }
    };

    const checkbox59_click = (event) => {
      //Add your code for this event here:
      $("#checkbox60").prop("checked", false);

      if ($("#checkbox60").is(":checked")) {
        $("#dropdown7").show();
        $(".dropdown7").show();
      } else {
        $("#dropdown7").hide();
        $(".dropdown7").hide();
      }
    };

    return {
      button4_click,
      checkbox59_click,
      checkbox60_click,
      cr_check,
      model,
    };
  },
});
</script>

<style></style>
