<template>
  <div class="card" style="width: 100%; border: 0px">
    <div class="row px-20 mb-7 mt-3">
      <div class="col">
        <h4 class="mb-3 text-primary">Tx Repeat</h4>
        <select
          id="dropdown2"
          class="form-select mb-3"
          aria-label=".form-select-lg example"
        >
          <option>Select Tx Repeat</option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>

        <h4 class="mb-3 text-primary">TX Conditions</h4>
        <select
          id="dropdown3"
          class="form-select mb-3"
          aria-label="Select example"
        >
          <option>Select TX Conditions</option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
        </select>

        <h4 class="mb-3 text-primary">Temperature Alarm Value</h4>
        <select
          id="dropdown4"
          class="form-select mb-3"
          aria-label="Select example"
        >
          <option>Select Temperature Alarm Value</option>
          <option value="0">-40</option>
          <option value="1">-39</option>
          <option value="2">-38</option>
          <option value="3">-37</option>
          <option value="4">-36</option>
          <option value="5">-35</option>
          <option value="6">-34</option>
          <option value="7">-33</option>
          <option value="8">-32</option>
          <option value="9">-31</option>
          <option value="10">-30</option>
          <option value="11">-29</option>
          <option value="12">-28</option>
          <option value="13">-27</option>
          <option value="14">-26</option>
          <option value="15">-25</option>
          <option value="16">-24</option>
          <option value="17">-23</option>
          <option value="18">-22</option>
          <option value="19">-21</option>
          <option value="20">-20</option>
          <option value="21">-19</option>
          <option value="22">-18</option>
          <option value="23">-17</option>
          <option value="24">-16</option>
          <option value="25">-15</option>
          <option value="26">-14</option>
          <option value="27">-13</option>
          <option value="28">-12</option>
          <option value="29">-11</option>
          <option value="30">-10</option>
          <option value="31">-9</option>
          <option value="32">-8</option>
          <option value="33">-7</option>
          <option value="34">-6</option>
          <option value="35">-5</option>
          <option value="36">-4</option>
          <option value="37">-3</option>
          <option value="38">-2</option>
          <option value="39">-1</option>
          <option value="40">0</option>
          <option value="41">1</option>
          <option value="42">2</option>
          <option value="43">3</option>
          <option value="44">4</option>
          <option value="45">5</option>
          <option value="46">6</option>
          <option value="47">7</option>
          <option value="48">8</option>
          <option value="49">9</option>
          <option value="50">10</option>
          <option value="51">11</option>
          <option value="52">12</option>
          <option value="53">13</option>
          <option value="54">14</option>
          <option value="55">15</option>
          <option value="56">16</option>
          <option value="57">17</option>
          <option value="58">18</option>
          <option value="59">19</option>
          <option value="60">20</option>
          <option value="61">21</option>
          <option value="62">22</option>
          <option value="63">23</option>
          <option value="64">24</option>
          <option value="65">25</option>
          <option value="66">26</option>
          <option value="67">27</option>
          <option value="68">28</option>
          <option value="69">29</option>
          <option value="70">30</option>
          <option value="71">31</option>
          <option value="72">32</option>
          <option value="73">33</option>
          <option value="74">34</option>
          <option value="75">35</option>
          <option value="76">36</option>
          <option value="77">37</option>
          <option value="78">38</option>
          <option value="79">39</option>
          <option value="80">40</option>
          <option value="81">41</option>
          <option value="82">42</option>
          <option value="83">43</option>
          <option value="84">44</option>
          <option value="85">45</option>
          <option value="86">46</option>
          <option value="87">47</option>
          <option value="88">48</option>
          <option value="89">49</option>
          <option value="90">50</option>
          <option value="91">51</option>
          <option value="92">52</option>
          <option value="93">53</option>
          <option value="94">54</option>
          <option value="95">55</option>
          <option value="96">56</option>
          <option value="97">57</option>
          <option value="98">58</option>
          <option value="99">59</option>
          <option value="100">60</option>
          <option value="101">61</option>
          <option value="102">62</option>
          <option value="103">63</option>
          <option value="104">64</option>
          <option value="105">65</option>
          <option value="106">66</option>
          <option value="107">67</option>
          <option value="108">68</option>
          <option value="109">69</option>
          <option value="110">70</option>
          <option value="111">71</option>
          <option value="112">72</option>
          <option value="113">73</option>
          <option value="114">74</option>
          <option value="115">75</option>
          <option value="116">76</option>
          <option value="117">77</option>
          <option value="118">78</option>
          <option value="119">79</option>
          <option value="120">80</option>
          <option value="121">81</option>
          <option value="122">82</option>
          <option value="123">83</option>
          <option value="124">84</option>
          <option value="125">85</option>
          <option value="126">86</option>
          <option value="127">87</option>
        </select>
      </div>
    </div>
  </div>
  <!-- <div class="col p-5 text-primary"> -->
  <div class="p-20 pt-0">
    <h4 class="text-primary">WakeUp intervals</h4>
    <p>Time to send uplink messages</p>
    <table class="table resposive" width="100%">
      <tbody>
        <!--row 1 start -->
        <tr>
          <td scope="row">
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox8"
              />
              <label class="form-check-label" for="checkbox8"> 00:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox13"
              />
              <label class="form-check-label" for="checkbox13"> 00:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox19"
              />
              <label class="form-check-label" for="checkbox19"> 01:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox28"
              />
              <label class="form-check-label" for="checkbox28"> 01:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox23"
              />
              <label class="form-check-label" for="checkbox23"> 02:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox20"
              />
              <label class="form-check-label" for="checkbox20"> 02:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox37"
              />
              <label class="form-check-label" for="checkbox37"> 03:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox32"
              />
              <label class="form-check-label" for="checkbox32"> 03:30</label>
            </div>
          </td>
        </tr>
        <!--row 1 end -->

        <!--row 2 start -->
        <tr>
          <td scope="row">
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox29"
              />
              <label class="form-check-label" for="checkbox29"> 04:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox46"
              />
              <label class="form-check-label" for="checkbox46"> 04:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox41"
              />
              <label class="form-check-label" for="checkbox41"> 05:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox38"
              />
              <label class="form-check-label" for="checkbox38"> 05:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox55"
              />
              <label class="form-check-label" for="checkbox55"> 06:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox50"
              />
              <label class="form-check-label" for="checkbox50"> 06:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox47"
              />
              <label class="form-check-label" for="checkbox47"> 07:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox58"
              />
              <label class="form-check-label" for="checkbox58"> 07:30</label>
            </div>
          </td>
        </tr>
        <!--row 2 end  -->

        <!--row 3 start -->
        <tr>
          <td scope="row">
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox9"
              />
              <label class="form-check-label" for="checkbox9"> 08:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox12"
              />
              <label class="form-check-label" for="checkbox12"> 08:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox18"
              />
              <label class="form-check-label" for="checkbox18"> 09:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox27"
              />
              <label class="form-check-label" for="checkbox27"> 09:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox24"
              />
              <label class="form-check-label" for="checkbox24"> 10:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox21"
              />
              <label class="form-check-label" for="checkbox21"> 10:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox36"
              />
              <label class="form-check-label" for="checkbox36"> 11:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox33"
              />
              <label class="form-check-label" for="checkbox33"> 11:30</label>
            </div>
          </td>
        </tr>
        <!--row 3 start -->

        <!--row 4 start -->
        <tr>
          <td scope="row">
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox30"
              />
              <label class="form-check-label" for="checkbox30"> 12:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox45"
              />
              <label class="form-check-label" for="checkbox45"> 12:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox42"
              />
              <label class="form-check-label" for="checkbox42"> 13:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox39"
              />
              <label class="form-check-label" for="checkbox39"> 13:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox54"
              />
              <label class="form-check-label" for="checkbox54"> 14:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox51"
              />
              <label class="form-check-label" for="checkbox51"> 14:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox48"
              />
              <label class="form-check-label" for="checkbox48"> 15:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox57"
              />
              <label class="form-check-label" for="checkbox57"> 15:30</label>
            </div>
          </td>
        </tr>
        <!--row 4 start -->

        <!--row 5 start -->
        <tr>
          <td scope="row">
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox10"
              />
              <label class="form-check-label" for="checkbox10"> 16:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox11"
              />
              <label class="form-check-label" for="checkbox11"> 16:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox17"
              />
              <label class="form-check-label" for="checkbox17"> 17:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox26"
              />
              <label class="form-check-label" for="checkbox26"> 17:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox25"
              />
              <label class="form-check-label" for="checkbox25"> 18:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox22"
              />
              <label class="form-check-label" for="checkbox22"> 18:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox35"
              />
              <label class="form-check-label" for="checkbox35"> 19:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox34"
              />
              <label class="form-check-label" for="checkbox34"> 19:30</label>
            </div>
          </td>
        </tr>
        <!--row 5 start -->

        <!--row 6 start -->
        <tr>
          <td scope="row">
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox31"
              />
              <label class="form-check-label" for="checkbox31"> 20:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox44"
              />
              <label class="form-check-label" for="checkbox44"> 20:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox43"
              />
              <label class="form-check-label" for="checkbox43"> 21:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox40"
              />
              <label class="form-check-label" for="checkbox40"> 21:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox53"
              />
              <label class="form-check-label" for="checkbox53"> 22:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox52"
              />
              <label class="form-check-label" for="checkbox52"> 22:30</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox49"
              />
              <label class="form-check-label" for="checkbox49"> 23:00</label>
            </div>
          </td>
          <td>
            <div
              class="form-check form-check-custom form-check-solid form-check-sm"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkbox56"
              />
              <label class="form-check-label" for="checkbox56"> 23:30</label>
            </div>
          </td>
        </tr>
        <!--row 6 start -->
        <tr>
          <td>.</td>
        </tr>
      </tbody>
    </table>
    <!-- </div> -->
    <!--buttons Selects -->
    <div class="card" style="width: 100%; border: 0px">
      <!--begin::Card header-->
      <div class="card-header border-0 p-0" style="margin-top: -38px">
        <!--begin::Card title-->
        <div class="col pt-4 text-primary">
          <!--buttons Selects -->
          <div class="card" style="width: 100%; border: 0px">
            <!--begin::Card header-->
            <div class="card-header border-0 p-0" style="margin-top: -38px">
              <!--begin::Card title-->
              <div class="card-title"></div>
              <!--begin::Card title-->

              <!--begin::Card toolbar-->
              <div class="card-toolbar mt-10 d-flex flex-row">
                <div
                  class="form-check form-check-custom form-check-solid form-check-sm mb-3 mt-3 d-flex d"
                  style="margin-right: 25rem"
                >
                  <label class="form-check-label" for="flexRadioLg">
                    <h3>Configuration Required Flag</h3></label
                  >
                  <input
                    class="form-check-input mx-5 border border-primaryd"
                    type="checkbox"
                    id="checkbox61"
                  />( CR )
                </div>
                <!--begin::Toolbar-->
                <div class="d-flex justify-content-end">
                  <button
                    @click="button2_click_1($event)"
                    id="button2"
                    class="btn btn-primary"
                  >
                    Select All
                  </button>
                </div>
                <div class="d-flex ms-4 justify-content-end">
                  <button
                    @click="button3_click_1($event)"
                    id="button3"
                    class="btn btn-success"
                  >
                    Deslect All
                  </button>
                </div>
              </div>
              <!--end::Card toolbar-->
            </div>
            <!--end::Card header-->
          </div>
          <!--buttons -->
        </div>
      </div>
      <div class="w-100"></div>

      <div class="row w-50 mx-auto">
        <div class="col">
          <button
            href="#"
            id="button1"
            @click="button1_click_1($event)"
            class="btn btn-primary"
          >
            Compose DownLink 1
          </button>
        </div>
        <div class="col">
          <input
            readonly
            class="form-control form-control-solid border border-primary bg-light rounded-2"
            type="text"
            v-model="model.hex"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import jQuery from "jquery";
const $ = jQuery;
// window.$ = $;

export default defineComponent({
  name: "downlink1",
  components: {},
  setup() {
    // const $ = jQuery;
    const model = ref({
      hex: null,
    });
    onMounted(() => {
      $("#checkbox8").prop("checked", true);
      $("#checkbox13").prop("checked", true);
      $("#checkbox19").prop("checked", true);
      $("#checkbox28").prop("checked", true);
      $("#checkbox23").prop("checked", true);
      $("#checkbox20").prop("checked", true);
      $("#checkbox37").prop("checked", true);
      $("#checkbox32").prop("checked", true);
      $("#checkbox29").prop("checked", true);
      $("#checkbox46").prop("checked", true);
      $("#checkbox41").prop("checked", true);
      $("#checkbox38").prop("checked", true);
      $("#checkbox55").prop("checked", true);
      $("#checkbox50").prop("checked", true);
      $("#checkbox47").prop("checked", true);
      $("#checkbox58").prop("checked", true);
      $("#checkbox9").prop("checked", true);
      $("#checkbox12").prop("checked", true);
      $("#checkbox18").prop("checked", true);
      $("#checkbox27").prop("checked", true);
      $("#checkbox24").prop("checked", true);
      $("#checkbox21").prop("checked", true);
      $("#checkbox36").prop("checked", true);
      $("#checkbox33").prop("checked", true);
      $("#checkbox30").prop("checked", true);
      $("#checkbox45").prop("checked", true);
      $("#checkbox42").prop("checked", true);
      $("#checkbox39").prop("checked", true);
      $("#checkbox54").prop("checked", true);
      $("#checkbox51").prop("checked", true);
      $("#checkbox48").prop("checked", true);
      $("#checkbox57").prop("checked", true);
      $("#checkbox10").prop("checked", true);
      $("#checkbox11").prop("checked", true);
      $("#checkbox17").prop("checked", true);
      $("#checkbox26").prop("checked", true);
      $("#checkbox25").prop("checked", true);
      $("#checkbox22").prop("checked", true);
      $("#checkbox35").prop("checked", true);
      $("#checkbox34").prop("checked", true);
      $("#checkbox31").prop("checked", true);
      $("#checkbox44").prop("checked", true);
      $("#checkbox43").prop("checked", true);
      $("#checkbox40").prop("checked", true);
      $("#checkbox53").prop("checked", true);
      $("#checkbox52").prop("checked", true);
      $("#checkbox49").prop("checked", true);
      $("#checkbox56").prop("checked", true);
    });

    const button3_click_1 = (event) => {
      $("#checkbox8").prop("checked", true);
      $("#checkbox8").prop("checked", false);
      $("#checkbox13").prop("checked", false);
      $("#checkbox19").prop("checked", false);
      $("#checkbox28").prop("checked", false);
      $("#checkbox23").prop("checked", false);
      $("#checkbox20").prop("checked", false);
      $("#checkbox37").prop("checked", false);
      $("#checkbox32").prop("checked", false);
      $("#checkbox29").prop("checked", false);
      $("#checkbox46").prop("checked", false);
      $("#checkbox41").prop("checked", false);
      $("#checkbox38").prop("checked", false);
      $("#checkbox55").prop("checked", false);
      $("#checkbox50").prop("checked", false);
      $("#checkbox47").prop("checked", false);
      $("#checkbox58").prop("checked", false);
      $("#checkbox9").prop("checked", false);
      $("#checkbox12").prop("checked", false);
      $("#checkbox18").prop("checked", false);
      $("#checkbox27").prop("checked", false);
      $("#checkbox24").prop("checked", false);
      $("#checkbox21").prop("checked", false);
      $("#checkbox36").prop("checked", false);
      $("#checkbox33").prop("checked", false);
      $("#checkbox30").prop("checked", false);
      $("#checkbox45").prop("checked", false);
      $("#checkbox42").prop("checked", false);
      $("#checkbox39").prop("checked", false);
      $("#checkbox54").prop("checked", false);
      $("#checkbox51").prop("checked", false);
      $("#checkbox48").prop("checked", false);
      $("#checkbox57").prop("checked", false);
      $("#checkbox10").prop("checked", false);
      $("#checkbox11").prop("checked", false);
      $("#checkbox17").prop("checked", false);
      $("#checkbox26").prop("checked", false);
      $("#checkbox25").prop("checked", false);
      $("#checkbox22").prop("checked", false);
      $("#checkbox35").prop("checked", false);
      $("#checkbox34").prop("checked", false);
      $("#checkbox31").prop("checked", false);
      $("#checkbox44").prop("checked", false);
      $("#checkbox43").prop("checked", false);
      $("#checkbox40").prop("checked", false);
      $("#checkbox53").prop("checked", false);
      $("#checkbox52").prop("checked", false);
      $("#checkbox49").prop("checked", false);
      $("#checkbox56").prop("checked", false);
    };

    const button2_click_1 = (event) => {
      //Add your code for this event here:
      $("#checkbox8").prop("checked", true);
      $("#checkbox13").prop("checked", true);
      $("#checkbox19").prop("checked", true);
      $("#checkbox28").prop("checked", true);
      $("#checkbox23").prop("checked", true);
      $("#checkbox20").prop("checked", true);
      $("#checkbox37").prop("checked", true);
      $("#checkbox32").prop("checked", true);
      $("#checkbox29").prop("checked", true);
      $("#checkbox46").prop("checked", true);
      $("#checkbox41").prop("checked", true);
      $("#checkbox38").prop("checked", true);
      $("#checkbox55").prop("checked", true);
      $("#checkbox50").prop("checked", true);
      $("#checkbox47").prop("checked", true);
      $("#checkbox58").prop("checked", true);
      $("#checkbox9").prop("checked", true);
      $("#checkbox12").prop("checked", true);
      $("#checkbox18").prop("checked", true);
      $("#checkbox27").prop("checked", true);
      $("#checkbox24").prop("checked", true);
      $("#checkbox21").prop("checked", true);
      $("#checkbox36").prop("checked", true);
      $("#checkbox33").prop("checked", true);
      $("#checkbox30").prop("checked", true);
      $("#checkbox45").prop("checked", true);
      $("#checkbox42").prop("checked", true);
      $("#checkbox39").prop("checked", true);
      $("#checkbox54").prop("checked", true);
      $("#checkbox51").prop("checked", true);
      $("#checkbox48").prop("checked", true);
      $("#checkbox57").prop("checked", true);
      $("#checkbox10").prop("checked", true);
      $("#checkbox11").prop("checked", true);
      $("#checkbox17").prop("checked", true);
      $("#checkbox26").prop("checked", true);
      $("#checkbox25").prop("checked", true);
      $("#checkbox22").prop("checked", true);
      $("#checkbox35").prop("checked", true);
      $("#checkbox34").prop("checked", true);
      $("#checkbox31").prop("checked", true);
      $("#checkbox44").prop("checked", true);
      $("#checkbox43").prop("checked", true);
      $("#checkbox40").prop("checked", true);
      $("#checkbox53").prop("checked", true);
      $("#checkbox52").prop("checked", true);
      $("#checkbox49").prop("checked", true);
      $("#checkbox56").prop("checked", true);
    };

    const insert_data = (buffer, startBit, bitCount, value) => {
      let bitStatus;
      let count;
      /* Get byte index and bit position */
      let index = parseInt(startBit / 8, 10);
      let bitPosition = parseInt(startBit % 8, 10);
      let iBuffer;

      for (count = 0; count < bitCount; count++) {
        iBuffer = 7 - index;
        bitStatus = get_bit_status(value, count);
        if (bitStatus === true) {
          /* Set bit */
          buffer[iBuffer] |= set_bit(buffer[iBuffer], bitPosition);
        } else {
          /* Clear bit */
          buffer[iBuffer] |= clear_bit(buffer[iBuffer], bitPosition);
        }

        if (bitPosition >= 7) {
          bitPosition = 0;
          index += 1;
        } else {
          bitPosition += 1;
        }
      }
    };

    const cr_check = (e) => {
      if ($("#checkbox61").is(":checked")) {
        $("#checkbox61").prop("checked", false);
      } else {
        $("#checkbox61").prop("checked", true);
      }
    };

    const button1_click_1 = (event) => {
      let myDownlink1 = new Uint8Array([0, 0, 0, 0, 0, 0, 0, 0]);

      insert_data(myDownlink1, 0, 3, 1);

      if ($("#dropdown2").val().length === 0) {
        $("#textBox1").value = "Please Select TX Repeat";
      } else {
        insert_data(myDownlink1, 3, 2, $("#dropdown2").val());

        if ($("#dropdown3").val().length === 0) {
          $("#textBox1").value = "Please Select TX Condition";
        } else {
          insert_data(myDownlink1, 5, 3, $("#dropdown3").val());

          if ($("#dropdown4").val().length === 0) {
            $("#textBox1").value = "Please Select Temp Alaram Value";
          } else {
            insert_data(myDownlink1, 8, 8, $("#dropdown4").val());

            if (
              $("#checkbox8").is(":checked") ||
              $("#checkbox13").is(":checked") ||
              $("#checkbox19").is(":checked") ||
              $("#checkbox28").is(":checked") ||
              $("#checkbox23").is(":checked") ||
              $("#checkbox20").is(":checked") ||
              $("#checkbox37").is(":checked") ||
              $("#checkbox32").is(":checked") ||
              $("#checkbox29").is(":checked") ||
              $("#checkbox46").is(":checked") ||
              $("#checkbox41").is(":checked") ||
              $("#checkbox38").is(":checked") ||
              $("#checkbox55").is(":checked") ||
              $("#checkbox50").is(":checked") ||
              $("#checkbox47").is(":checked") ||
              $("#checkbox58").is(":checked") ||
              $("#checkbox9").is(":checked") ||
              $("#checkbox12").is(":checked") ||
              $("#checkbox18").is(":checked") ||
              $("#checkbox27").is(":checked") ||
              $("#checkbox24").is(":checked") ||
              $("#checkbox21").is(":checked") ||
              $("#checkbox36").is(":checked") ||
              $("#checkbox33").is(":checked") ||
              $("#checkbox30").is(":checked") ||
              $("#checkbox45").is(":checked") ||
              $("#checkbox42").is(":checked") ||
              $("#checkbox39").is(":checked") ||
              $("#checkbox54").is(":checked") ||
              $("#checkbox51").is(":checked") ||
              $("#checkbox48").is(":checked") ||
              $("#checkbox57").is(":checked") ||
              $("#checkbox10").is(":checked") ||
              $("#checkbox11").is(":checked") ||
              $("#checkbox17").is(":checked") ||
              $("#checkbox26").is(":checked") ||
              $("#checkbox25").is(":checked") ||
              $("#checkbox22").is(":checked") ||
              $("#checkbox35").is(":checked") ||
              $("#checkbox34").is(":checked") ||
              $("#checkbox31").is(":checked") ||
              $("#checkbox44").is(":checked") ||
              $("#checkbox43").is(":checked") ||
              $("#checkbox40").is(":checked") ||
              $("#checkbox53").is(":checked") ||
              $("#checkbox52").is(":checked") ||
              $("#checkbox49").is(":checked") ||
              $("#checkbox56").is(":checked")
            ) {
              insert_data(myDownlink1, 16, 1, $("#checkbox8").is(":checked"));
              insert_data(myDownlink1, 17, 1, $("#checkbox13").is(":checked"));
              insert_data(myDownlink1, 18, 1, $("#checkbox19").is(":checked"));
              insert_data(myDownlink1, 19, 1, $("#checkbox28").is(":checked"));
              insert_data(myDownlink1, 20, 1, $("#checkbox23").is(":checked"));
              insert_data(myDownlink1, 21, 1, $("#checkbox20").is(":checked"));
              insert_data(myDownlink1, 22, 1, $("#checkbox37").is(":checked"));
              insert_data(myDownlink1, 23, 1, $("#checkbox32").is(":checked"));
              insert_data(myDownlink1, 24, 1, $("#checkbox29").is(":checked"));
              insert_data(myDownlink1, 25, 1, $("#checkbox46").is(":checked"));
              insert_data(myDownlink1, 26, 1, $("#checkbox41").is(":checked"));
              insert_data(myDownlink1, 27, 1, $("#checkbox38").is(":checked"));
              insert_data(myDownlink1, 28, 1, $("#checkbox55").is(":checked"));
              insert_data(myDownlink1, 29, 1, $("#checkbox50").is(":checked"));
              insert_data(myDownlink1, 30, 1, $("#checkbox47").is(":checked"));
              insert_data(myDownlink1, 31, 1, $("#checkbox58").is(":checked"));
              insert_data(myDownlink1, 32, 1, $("#checkbox9").is(":checked"));
              insert_data(myDownlink1, 33, 1, $("#checkbox12").is(":checked"));
              insert_data(myDownlink1, 34, 1, $("#checkbox18").is(":checked"));
              insert_data(myDownlink1, 35, 1, $("#checkbox27").is(":checked"));
              insert_data(myDownlink1, 36, 1, $("#checkbox24").is(":checked"));
              insert_data(myDownlink1, 37, 1, $("#checkbox21").is(":checked"));
              insert_data(myDownlink1, 38, 1, $("#checkbox36").is(":checked"));
              insert_data(myDownlink1, 39, 1, $("#checkbox33").is(":checked"));
              insert_data(myDownlink1, 40, 1, $("#checkbox30").is(":checked"));
              insert_data(myDownlink1, 41, 1, $("#checkbox45").is(":checked"));
              insert_data(myDownlink1, 42, 1, $("#checkbox42").is(":checked"));
              insert_data(myDownlink1, 43, 1, $("#checkbox39").is(":checked"));
              insert_data(myDownlink1, 44, 1, $("#checkbox54").is(":checked"));
              insert_data(myDownlink1, 45, 1, $("#checkbox51").is(":checked"));
              insert_data(myDownlink1, 46, 1, $("#checkbox48").is(":checked"));
              insert_data(myDownlink1, 47, 1, $("#checkbox57").is(":checked"));
              insert_data(myDownlink1, 48, 1, $("#checkbox10").is(":checked"));
              insert_data(myDownlink1, 49, 1, $("#checkbox11").is(":checked"));
              insert_data(myDownlink1, 50, 1, $("#checkbox17").is(":checked"));
              insert_data(myDownlink1, 51, 1, $("#checkbox26").is(":checked"));
              insert_data(myDownlink1, 52, 1, $("#checkbox25").is(":checked"));
              insert_data(myDownlink1, 53, 1, $("#checkbox22").is(":checked"));
              insert_data(myDownlink1, 54, 1, $("#checkbox35").is(":checked"));
              insert_data(myDownlink1, 55, 1, $("#checkbox34").is(":checked"));
              insert_data(myDownlink1, 56, 1, $("#checkbox31").is(":checked"));
              insert_data(myDownlink1, 57, 1, $("#checkbox44").is(":checked"));
              insert_data(myDownlink1, 58, 1, $("#checkbox43").is(":checked"));
              insert_data(myDownlink1, 59, 1, $("#checkbox40").is(":checked"));
              insert_data(myDownlink1, 60, 1, $("#checkbox53").is(":checked"));
              insert_data(myDownlink1, 61, 1, $("#checkbox52").is(":checked"));
              insert_data(myDownlink1, 62, 1, $("#checkbox49").is(":checked"));
              insert_data(myDownlink1, 63, 1, $("#checkbox56").is(":checked"));

              insert_data(myDownlink1, 15, 1, $("#checkbox61").is(":checked"));

              let hexString = toHexString(myDownlink1);
              model.value.hex = hexString;
            } else {
              $("#textBox1").value = "Select atleast 1 wake-up Interval";
            }
          }
        }
      }
    };
    function toHexString(byteArray) {
      return Array.prototype.map
        .call(byteArray, function (byte) {
          return ("0" + (byte & 0xff).toString(16)).slice(-2);
        })
        .join("")
        .toUpperCase();
    }

    function get_bit_status(value, bitNumber) {
      var ret_status = false;
      if (value & (1 << bitNumber)) {
        ret_status = true;
      }

      return ret_status;
    }
    function set_bit(value, bitNumber) {
      /* Note: Maximum 32 bit value shift is possible in JS */
      value |= 1 << bitNumber;
      return value;
    }
    function clear_bit(value, bitNumber) {
      /* Note: Maximum 32 bit value shift is possible in JS */
      value &= ~(1 << bitNumber);
      return value;
    }

    return {
      button1_click_1,
      button2_click_1,
      button3_click_1,
      cr_check,
      model,
    };
  },
});
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
